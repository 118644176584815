
.view-script-main{
    display: flex;
    flex-direction: column;
    height: calc(100vh - 100px);
    .view-script-content{
        display: flex;
        flex-direction: column;
        flex: 1;
        width: 100%;
        border-radius: 10px;
        background-color: #fff;
            .view-script-item{
                padding: 30px;
                box-sizing: border-box;
                .view-script-item-top{
                    display: flex;
                    align-items: center;
                    .view-script-img{
                        width:288px;
                        height:179px;
                        margin-right:20px;
                        img{
                            width:288px;
                            height:179px;
                        }
                    }
                    .view-script-right{
                        flex: 1;
                        width: 1%;
                        .view-script-name{
                            font-size: 20px;
                            color: #333;
                        }
                        .view-script-fenlei{
                            margin: 40px 0;
                            font-size: 16px;
                            color: #666;
                        }
                        .view-script-jianjie{
                            font-size: 16px;
                            color: #666;
                        }
                    }
                }
                .view-script-body{
                    display: flex;
                    flex-direction: column;
                    border: 2px solid #574DED;
                    box-shadow: 0px 10px 0px 0px #ECEBFF;
                    border-radius:25px;
                    margin:42px 0;
                    .view-script-body-title{
                        width: 160px;
                        height: 32px;
                        background: #584EEE;
                        border-radius: 16px;
                        line-height: 32px;
                        text-align: center;
                        color: #fff;
                        margin: -12px 0 0 24px;
                        position: relative;
                        &:before{
                            content: "";
                            background: #fff;
                            -webkit-border-radius: 50%;
                            border-radius: 50%;
                            width: 8px;
                            height: 8px;
                            position: absolute;
                            top: 12px;
                            left: 20px
                        }
                        &:after{
                            content: "";
                            background: #fff;
                            -webkit-border-radius: 50%;
                            border-radius: 50%;
                            width: 8px;
                            height: 8px;
                            position: absolute;
                            top: 12px;
                            right: 20px
                        }
                    }
                    .view-script-body-content{
                        display: flex;
                        min-height: 100px;
                        padding-left: 22px;
                    }
                }
                .view-script-footer{
                    margin-top: 10px;
                }
            }
    }
    ::v-deep .el-table__body tr:hover>td{
        background-color: #F0F3FF;
    }
    ::v-deep .el-table--medium td,::v-deep .el-table--medium th {
        border-right: 2px solid #fff;
    }
    ::v-deep .el-table--medium td{
        background:#F0F3FF;
        border-bottom: 2px solid #fff
    }
    .breadcrumb-item{
        display: flex;
        justify-content: space-between;
        .back-btn {
            margin-top: -12px;
        }
    }
}
